import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Error } from '@iarcpsu/emanufac-components/src/components/Error';
import { InformationForm } from '@iarcpsu/emanufac-components/src/components/Forms';
import Loading from '@iarcpsu/emanufac-components/src/components/Loading';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../redux/actions';

function Information({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const [logoImage, setLogoImage] = useState([]);
  const [rightLogoImage, setRightLogoImage] = useState([]);
  const [stampLogoImage, setStampLogoImage] = useState([]);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm();

  useEffect(() => {
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      await dispatch(
        actions.informationPut(information.id, {
          ...data,
          logo: _.isEmpty(logoImage)
            ? undefined
            : {
                image: logoImage[0]?.data_url,
                imageType: 'profile',
                alt: '',
              },
          right_logo: _.isEmpty(rightLogoImage)
            ? undefined
            : {
                image: rightLogoImage[0]?.data_url,
                imageType: 'profile',
                alt: '',
              },
          stamp_logo: _.isEmpty(stampLogoImage)
            ? undefined
            : {
                image: stampLogoImage[0]?.data_url,
                imageType: 'profile',
                alt: '',
              },
          setting: {
            ...information?.setting,
            information: {
              ...information?.setting?.information,
              ...data?.setting?.information,
            },
          },
        }),
      );
      setLogoImage([]);
      setRightLogoImage([]);
      setStampLogoImage([]);

      alert('สำเร็จ');
      await dispatch(actions.informationAll());
    } catch (error) {
      console.error(error);
    }
  };

  console.log('logoImage', logoImage);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InformationForm
          Controller={Controller}
          control={control}
          errors={errors}
          information={information}
          selectedImage={logoImage}
          setSelectedImage={setLogoImage}
          rightLogoImage={rightLogoImage}
          setRightLogoImage={setRightLogoImage}
          watch={watch}
          stampImage={stampLogoImage}
          setStampImage={setStampLogoImage}
        />
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
Information.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Information.defaultProps = {
  title: '',
  subtitle: '',
};

export default Information;
