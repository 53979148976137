/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BackButton,
  CardManufacturingOrderAnalysis,
  DaySpotAndRangePicker,
  EmployeeWorkLoadCard,
  Error,
  Loading,
  StepAnalysisDashboardElements,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { config } from '@/configs';
import * as actions from '@/redux/actions';
import { useQuery } from '@/utils/functions';

function ManufacturingDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const browserQuery = useQuery();

  const employee = useSelector((state) => state.employee);
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const step = useSelector((state) => state.step);
  const stepLog = useSelector((state) => state.stepLog);
  const information = useSelector((state) => state.information);

  // const isFullCalendar = getStorage('full_calendar');
  const [startDate, setStartDate] = useState(
    browserQuery.get('start')
      ? dayjs(browserQuery.get('start')).startOf('month').toISOString()
      : dayjs().startOf('month').toISOString(),
  );
  const [periodText, setPeriodText] = useState('ในเดือนล่าสุด');
  const [endDate, setEndDate] = useState(
    browserQuery.get('end')
      ? dayjs(browserQuery.get('end')).endOf('month').toISOString()
      : dayjs().endOf('month').toISOString(),
  );
  const [isReady, setIsReady] = useState(false);
  const [isUseRange, setIsUseRange] = useState(false);
  const [tempProductCode, setTempProductCode] = useState('');
  const [productCode, setProductCode] = useState('');

  // console.log('isFullCalendar', isFullCalendar);

  useEffect(() => {
    const fetchManufacturingOrder = async () => {
      try {
        setIsReady(false);
        await dispatch(
          actions.manufacturingOrderAll({
            all: true,
            startDate,
            endDate,
            analyzed: true,
            page: 1,
            productCode,
            fetchProduct: productCode === '' ? '' : true
          }),
        );
        setIsReady(true);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchStep = async () => {
      try {
        await dispatch(
          actions.stepAll({
            all: true,
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            size: config.maxFetchSize,
            productCode
          }),
        );
        await dispatch(
          actions.stepLogAll({
            all: true,
            startDate: dayjs(startDate).format('YYYY-MM-DD'),
            endDate: dayjs(endDate).format('YYYY-MM-DD'),
            size: config.maxFetchSize,
          productCode
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmployee = async () => {
      try {
        await dispatch(actions.employeeAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    // const checkFullCalendar = async () => {
    //   if (!isFullCalendar) {
    //     await setStorage('full_calendar', true);
    //   }
    // };

    fetchEmployee();
    fetchManufacturingOrder();
    fetchStep();

    return () => {};
  }, [startDate, endDate, productCode]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangePeriod = (value) => {
    if (value === 'week') {
      setStartDate(dayjs().startOf('week').toISOString());
      setEndDate(dayjs().endOf('week').toISOString());
      setPeriodText('ในสัปดาห์ล่าสุด');
    } else if (value === 'month') {
      setStartDate(dayjs().startOf('month').toISOString());
      setEndDate(dayjs().endOf('month').toISOString());
      setPeriodText('ในเดือนล่าสุด');
    } else if (value === 'year') {
      setStartDate(dayjs().startOf('year').toISOString());
      setEndDate(dayjs().endOf('year').toISOString());
      setPeriodText('ในปีล่าสุด');
    }
  };
  const renderPeriod = () => (
    <div>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel id="type" size={'small'}>
          ช่วงเวลา
        </InputLabel>
        <Select
          label="ช่วงเวลา"
          size={'small'}
          fullWidth
          defaultValue="month"
          onChange={(e) => {
            handleChangePeriod(e.target.value);
          }}
        >
          <MenuItem value="week">สัปดาห์ล่าสุด</MenuItem>
          <MenuItem value="month">เดือนล่าสุด</MenuItem>
          <MenuItem value="year">ปีล่าสุด</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const setCurrentDayFunction = (selectedDate) => {
    console.log('Selected Date');
    setStartDate(dayjs(selectedDate).startOf('month').toISOString());
    setEndDate(dayjs(selectedDate).endOf('month').toISOString());
  };

   useEffect(() => {
     const delayDebounceFn = setTimeout(() => {
       setProductCode(tempProductCode);
     }, 500);
     return () => clearTimeout(delayDebounceFn);
   }, [tempProductCode]);

  if (step.isLoading || employee.isLoading || manufacturingOrder.isLoading) {
    return <Loading />;
  }
  if (
    step.isCompleted &&
    employee.isCompleted &&
    manufacturingOrder.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between my-2">
          <BackButton />
          <div className="flex gap-2">
            <div className="flex gap-1">
              <Link to="/mms/manufacturing-order-report">
                <Button variant="contained">รายงานรายเดือน</Button>
              </Link>{' '}
              <Link to="/mms/dashboard/calendar">
                <Button variant="contained" color="info">
                  ปฏิทินคำสั่งผลิต
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="my-2 flex flex-wrap">
          <div className="w-full lg:w-3/3 ">
            <CardManufacturingOrderAnalysis
              manufacturingOrder={manufacturingOrder}
              selectedMonthDate={
                periodText === 'ในเดือนล่าสุด' ? startDate : null
              }
              showRevenue={false}
              showProductionResult={false}
              showMoreInfoBox
              isReady={isReady}
              isUseRange={isUseRange}
              endDate={endDate}
              productCode={productCode}
            />
          </div>
          <div className="w-full my-1">
            <Card>
              <CardContent>
                <DaySpotAndRangePicker
                  endDate={endDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  state="month"
                  flex
                  setParentUseRange={setIsUseRange}
                  addSearchQuery
                  enable
                  label="เลือกเดือนที่ต้องการ"
                  anotherQuery="?"
                  showTypeSelector
                />
                {/** TODO: Option It */}
                <div>
                  ค้นหาจากรหัสสินค้า
                </div>
                <div className="w-full lg:w-1/4 md:w-1/2">
                  <TextField size="small" label="รหัสสินค้า หลายรหัสขั้นด้วย ," fullWidth onChange={(e) => setTempProductCode(e.target.value)} value={tempProductCode} />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="w-full  my-2">
            <StepAnalysisDashboardElements
              step={step}
              stepLog={stepLog}
              isReady={isReady}
              manufacturingOrder={manufacturingOrder}
              startDate={startDate}
              endDate={endDate}
              showClaimedWork={information?.setting?.mms?.enableClaimedMO}
            />
          </div>
        </div>

        <EmployeeWorkLoadCard
          employee={employee}
          step={step}
          stepLog={stepLog}
          periodText={periodText}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    );
  }
  return <Error />;
}
ManufacturingDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufacturingDashboard;
