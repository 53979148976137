import React from 'react';
import { Card, FormControlLabel, Switch, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import PlaceholderLogo from '../../../assets/images/diprom.png';
import ImageUpload from '../../ImageUpload/ImageUpload';

export function InformationForm({
  errors,
  information,
  Controller,
  control,
  selectedImage,
  setSelectedImage,
  rightLogoImage,
  setRightLogoImage,
  stampImage,
  setStampImage,
  watch,
}) {
  const renderSystemInfo = () => (
    <div className="flex flex-wrap">
      <div className="w-full px-1 py-2 flex justify-center my-2">
        <img
          src={information?.logo?.url || PlaceholderLogo}
          className="h-48 rounded-md"
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={information ? information.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={information ? information.description : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              size={'small'}
              required
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'url'}
          control={control}
          defaultValue={information ? information.url : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              type="url"
              label="URL ของระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.url && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.name'}
          control={control}
          defaultValue={information ? information?.sponsor?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.website'}
          control={control}
          defaultValue={information ? information?.sponsor?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์ผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 justify-center">
        <ImageUpload
          images={selectedImage}
          maxNumber={1}
          previewSize={400}
          setImages={setSelectedImage}
        />
      </div>
    </div>
  );
  const renderCompanyInfo = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.name'}
          control={control}
          defaultValue={information ? information.owner?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อบริษัท / หน่วยงาน เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.website'}
          control={control}
          defaultValue={information ? information.owner?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.telephone'}
          control={control}
          defaultValue={information ? information.owner?.telephone : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.email'}
          control={control}
          defaultValue={information ? information.owner?.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'owner.tax'}
          control={control}
          defaultValue={information ? information.owner?.tax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.tax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.house_number'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.house_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.house_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.village_number'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.village_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมู่ที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.village_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.road'}
          control={control}
          defaultValue={information ? information.owner?.address?.road : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.subdistrict'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.subdistrict : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำบล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.subdistrict && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.district'}
          control={control}
          defaultValue={information ? information.owner?.address?.district : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อำเภอ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.province'}
          control={control}
          defaultValue={information ? information.owner?.address?.province : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.postcode'}
          control={control}
          defaultValue={information ? information.owner?.address?.postcode : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.postcode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.country'}
          control={control}
          defaultValue={information ? information.owner?.address?.country : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ประเทศ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.country && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );

  const renderDocumentSetting = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full  px-1 py-2">
        <Controller
          name={'setting.information.enable_right_logo'}
          control={control}
          defaultValue={information?.setting?.information?.enable_right_logo}
          render={({ field }) => (
            <FormControlLabel
              label="เปิดการแสดงโลโก้ด้านขวาในบางเอกสาร เช่น ใบเสนอราคา"
              control={<Switch {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
      {watch('setting.information.enable_right_logo') && (
        <div className="w-full  px-1 py-2">
          <ImageUpload
            images={rightLogoImage}
            maxNumber={1}
            previewSize={400}
            setImages={setRightLogoImage}
          />
        </div>
      )}
      {watch('setting.information.enable_right_logo') && (
        <div className="w-full lg:w-1/2  px-1 py-2">
          <img src={information?.right_logo?.url} className="h-24" />
        </div>
      )}
      <div className="w-full  px-1 py-2">
        <div className="my-2 font-semibold">ตราประทับ</div>
        <div className="w-full  px-1 py-2">
          <ImageUpload
            images={stampImage}
            maxNumber={1}
            previewSize={400}
            setImages={setStampImage}
          />
        </div>{' '}
        <div className="w-full lg:w-1/2  px-1 py-2">
          <img src={information?.stamp_logo?.url} className="h-24" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12 px-2 mb-2">
          <Card className="p-4">
            <div className="font-semibold font-display ">ข้อมูลระบบ</div>
            {renderSystemInfo()}
          </Card>
        </div>

        <div className="w-full lg:w-8/12 px-2 ">
          <Card className="p-4">
            <div className="font-semibold font-display mb-2">ข้อมูลบริษัท</div>
            {renderCompanyInfo()}{' '}
          </Card>
          <Card className="p-4 my-2">
            <div className="font-semibold font-display mb-2">
              การแสดงในเอกสาร
            </div>
            {renderDocumentSetting()}{' '}
          </Card>
        </div>
      </div>
    </div>
  );
}

InformationForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    owner: PropTypes.object,
    sponsor: PropTypes.object,
    url: PropTypes.object,
  }),
  information: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  selectedImage: PropTypes.arrayOf(PropTypes.object),
  setSelectedImage: PropTypes.node.isRequired,
};

InformationForm.defaultProps = {
  information: null,
};

export default InformationForm;
