import AssignManufacturingOrder from './AssignManufacturingOrder';
import CreateManufacturingOrder from './CreateManufacturingOrder';
import CreateProcessInMO from './CreateProcessInMO';
import CustomDetailManufacturingOrder from './CustomDetailManufacturingOrder';
import DetailManufacOrderA from './DetailManufacOrderA';
import DetailManufacOrderB from './DetailManufacOrderB';
import DetailManufacOrderC from './DetailManufacOrderC';
import EditManufacturingOrder from './EditManufacturignOrder';
import EditManufacturingOrderDeposit from './EditManufacturignOrderDeposit';
import HistoryManufacturingOrder from './HistoryManufacturingOrder';
import ManufacturingOrderDeposit from './ManufacturignOrderDeposit';
import ManufacturingOrderBom from './ManufacturingOrderBom';
import ManufacturingOrderByStatus from './ManufacturingOrderByStatus';
import ManufactoringOrders from './Manufacturingorders';
import ManufacturingPlaning from './ManufacturingPlaning';
import RecieveMO from './RecieveMO';
import ReportDisplay from './ReportDisplay';
/**
 * @namespace MMS/ManufacturingOrder
 * @description การจัดการคำสั่งผลิตทั้งหมด ตั้งแต่สร้าง แก้ไข รายการ ประวัติและอื่นๆ
 */
export {
  AssignManufacturingOrder,
  CreateManufacturingOrder,
  CreateProcessInMO,
  CustomDetailManufacturingOrder,
  DetailManufacOrderA,
  DetailManufacOrderB,
  DetailManufacOrderC,
  EditManufacturingOrder,
  EditManufacturingOrderDeposit,
  HistoryManufacturingOrder,
  ManufactoringOrders,
  ManufacturingOrderBom,
  ManufacturingOrderByStatus,
  ManufacturingOrderDeposit,
  ManufacturingPlaning,
  RecieveMO,
  ReportDisplay,
};

export default {
  AssignManufacturingOrder,
  CreateManufacturingOrder,
  DetailManufacOrderA,
  DetailManufacOrderB,
  HistoryManufacturingOrder,
  ManufactoringOrders,
  CreateProcessInMO,
  ManufacturingOrderBom,
  EditManufacturingOrder,
  DetailManufacOrderC,
  CustomDetailManufacturingOrder,
  ManufacturingPlaning,
  ReportDisplay,
  RecieveMO,
  ManufacturingOrderByStatus,
  ManufacturingOrderDeposit,
  EditManufacturingOrderDeposit,
};
