/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Chip, Link as MUILink, Step, StepLabel, Stepper } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import theme from '../../theme';

const TimelineStepper = ({ information, selectedProcess }) => {
  const startStep = _.find(
    selectedProcess?.steps,
    (step) => step?.is_process_splitting_point,
  );
  const startIndex = startStep?.index;

  const endStep = _.find(
    selectedProcess?.steps,
    (step) => step?.is_process_merging_point,
  );
  const endIndex = endStep?.index;
  const filterStep = _.filter(
    selectedProcess?.steps,
    (step) => step?.index >= startIndex && step?.index <= endIndex,
  );

  const filterStepIndex = _.map(filterStep, (step) => step?.index);
  console.log('Filter Step index', filterStepIndex);

  const findCurrentStep = () => {
    // console.log('All Step Amount', selectedProcess?.product?.steps);
    const allStepAmount =
      _.size(selectedProcess?.product?.steps) +
      (information?.setting?.mms?.showPrepareStep ? 1 : 0) +
      (information?.setting?.mms?.showPrepareMaterial ? 1 : 0) +
      (information?.setting?.mms?.showProductHandler ? 1 : 0);
    // 3 are start/material decrease and product handling
    // เสร็จแล้ว
    if (selectedProcess?.completed) {
      return allStepAmount;
    }
    if (
      selectedProcess?.current?.index + 1 ===
      _.size(selectedProcess?.product?.steps)
    ) {
      if (selectedProcess?.current?.step?.completed === true) {
        return allStepAmount - 1;
      }
    }
    // ยังไม่เริ่ม
    if (selectedProcess?.isRequestMaterial) {
      if (
        selectedProcess?.current?.index === null ||
        selectedProcess?.current?.index === -1
      ) {
        return 1;
      }
      return (
        selectedProcess?.current?.index +
        (information?.setting?.mms?.showPrepareStep ? 1 : 0) +
        (information?.setting?.mms?.showPrepareMaterial ? 1 : 0)
      );
    }
    if (
      selectedProcess?.current?.index === null ||
      selectedProcess?.current?.index === -1
    ) {
      return 0;
    }
    return (
      selectedProcess?.current?.index +
      (information?.setting?.mms?.showPrepareStep ? 1 : 0) +
      (information?.setting?.mms?.showPrepareMaterial ? 1 : 0)
    );
  };

  const stepList = _.orderBy(selectedProcess?.steps, ['index'], ['asc']);

  return (
    <Stepper alternativeLabel activeStep={findCurrentStep()}>
      {information?.setting?.mms?.showPrepareMaterial && (
        <Step>
          <StepLabel>
            <h5 className="font-semibold font-display ">เบิกวัตถุดิบ</h5>
          </StepLabel>
        </Step>
      )}

      {information?.setting?.mms?.showPrepareStep && (
        <Step>
          <StepLabel>
            <h5 className="font-semibold font-display ">เตรียมการผลิต</h5>
          </StepLabel>
        </Step>
      )}

      {_.isEmpty(stepList) && (
        <Step>
          <StepLabel>
            <h5 className="font-semibold font-display ">ผลิตสินค้า</h5>
          </StepLabel>
        </Step>
      )}

      {_.map(stepList, (_productStep, index) => (
        <Step key={index}>
          <StepLabel>
            {selectedProcess?.is_during_minor_process ? (
              _.includes(filterStepIndex, _productStep?.index) ? (
                <MUILink
                  className="font-semibold font-display text-gray-600"
                  underline="none"
                  style={{ color: theme?.palette?.grey?.[600] }}
                >
                  {_productStep?.name}
                </MUILink>
              ) : (
                <Link to={`/mms/works/view/${_productStep?._id}`}>
                  <MUILink
                    className="font-semibold font-display "
                    underline="hover"
                    style={{ color: theme?.palette?.primary?.dark }}
                  >
                    {_productStep?.name}
                  </MUILink>{' '}
                </Link>
              )
            ) : (
              <Link to={`/mms/works/view/${_productStep?._id}`}>
                <MUILink
                  className="font-semibold font-display "
                  underline="hover"
                  style={{ color: theme?.palette?.primary?.dark }}
                >
                  {_productStep?.name}
                </MUILink>
              </Link>
            )}
          </StepLabel>
          <h6 className="text-xs text-gray-500 text-center">
            {_productStep?.responsible?.department?.name}
          </h6>
          {_productStep?.is_process_splitting_point && (
            <div className="text-xs text-center my-1">
              <Chip
                label="เริ่มต้นแยกไลน์"
                size="small"
                color={
                  _.includes(filterStepIndex, _productStep?.index) &&
                  selectedProcess?.is_during_minor_process
                    ? 'default'
                    : 'primary'
                }
              ></Chip>
            </div>
          )}
          {_productStep?.is_process_merging_point && (
            <div className="text-xs text-center my-1">
              <Chip
                label="สิ้นสุดแยกไลน์"
                size="small"
                color={
                  _.includes(filterStepIndex, _productStep?.index) &&
                  selectedProcess?.is_during_minor_process
                    ? 'default'
                    : 'primary'
                }
              ></Chip>
            </div>
          )}
        </Step>
      ))}

      {information?.setting?.mms?.showProductHandler && (
        <Step>
          <StepLabel>
            <h5 className="font-semibold font-display ">
              {' '}
              {information?.setting?.mms?.productionHandler
                ?.productionHandlerName || 'ดำเนินการกับผลผลิต'}
            </h5>
          </StepLabel>
        </Step>
      )}
    </Stepper>
  );
};

TimelineStepper.propTypes = {
  information: PropTypes.object,
  selectedProcess: PropTypes.object,
};

export default TimelineStepper;
