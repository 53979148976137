/* eslint-disable no-confusing-arrow */
import { QUOTATION_STATUS } from '@iarcpsu/emanufac-constant';
import dayjs from 'dayjs';

import findQuotationStatus from '../functions/findQuotationStatus';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'หมายเลขใบเสนอราคา',
    value: (col) => `${col?.prefix}${col?.running_number}`,
  },
  {
    label: 'วันที่เสนอราคา',
    value: (col) => dayjs(col?.start_date).format('D/MM/BBBB'),
  },
  {
    label: 'วันกำหนดจัดส่ง',
    value: (col) => dayjs(col?.expected_date).format('D/MM/BBBB'),
  },
  {
    label: 'ลูกค้า',
    value: (col) =>
      `${col?.customer?.type_code || ''} ${col?.customer?.name || ''}`,
  },
  {
    label: 'ผู้เสนอราคา / พนักงานขาย',
    value: (col) =>
      `${col?.sales?.firstname || ''} ${col?.sales?.lastname || ''}`,
  },
  {
    label: 'ผู้พิมพ์เข้าในระบบ',
    value: (col) =>
      `${col?.creator?.firstname || ''} ${col?.creator?.lastname || ''}`,
  },
  {
    label: 'สถานะ',
    value: (col) => QUOTATION_STATUS[findQuotationStatus(col)]?.description,
  },
  {
    label: 'ความเร่งด่วนของงาน',
    value: (col) => (col?.express ? 'เร่งด่วน' : 'ปกติ'),
  },
  {
    label: 'ราคาสินค้า',
    value: (col) => col?.product_price,
  },
  {
    label: 'ค่าธรรมเนียมการผลิตด่วน',
    value: (col) => col?.express_fee || 0,
  },
  {
    label: 'ส่วนลด',
    value: (col) => col?.discount || 0,
  },
  {
    label: 'ราคาสุทธิ',
    value: (col) => col?.total_price,
  },
];

const enableWhenColPrimary = (col, result) => {
  if (col?.isPrimary) {
    return result;
  }
  return '';
};

const columnWithProcess = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'หมายเลขใบเสนอราคา',
    value: (col) =>
      enableWhenColPrimary(col, `${col?.prefix}${col?.running_number}`),
  },
  {
    label: 'วันที่เสนอราคา',
    value: (col) =>
      enableWhenColPrimary(col, dayjs(col?.start_date).format('D/MM/BBBB')),
  },
  {
    label: 'วันกำหนดจัดส่ง',
    value: (col) =>
      enableWhenColPrimary(col, dayjs(col?.expected_date).format('D/MM/BBBB')),
  },
  {
    label: 'ลูกค้า',
    value: (col) =>
      enableWhenColPrimary(
        col,
        `${col?.customer?.type_code || ''} ${col?.customer?.name || ''}`,
      ),
  },
  {
    label: 'ผู้เสนอราคา / พนักงานขาย',
    value: (col) =>
      enableWhenColPrimary(
        col,
        `${col?.sales?.firstname || ''} ${col?.sales?.lastname || ''}`,
      ),
  },
  {
    label: 'ผู้พิมพ์เข้าในระบบ',
    value: (col) =>
      enableWhenColPrimary(
        col,
        `${col?.creator?.firstname || ''} ${col?.creator?.lastname || ''}`,
      ),
  },
  {
    label: 'สถานะ',
    value: (col) =>
      enableWhenColPrimary(
        col,
        QUOTATION_STATUS[findQuotationStatus(col)]?.description,
      ),
  },
  {
    label: 'ความเร่งด่วนของงาน',
    value: (col) =>
      enableWhenColPrimary(col, col?.express ? 'เร่งด่วน' : 'ปกติ'),
  },
  {
    label: 'รหัสสินค้า',
    value: (col) => {
      if (col?.process_produce_material_instead) {
        return col?.process_product_as_material?.type_code || '-';
      }
      if (col?.process_produce_base_project_instead) {
        return '-';
      }
      return col?.process_product?.type_code || '-';
    },
  },
  {
    label: 'ชื่อสินค้า',
    value: (col) => {
      if (col?.process_produce_material_instead) {
        return col?.process_product_as_material?.name || '-';
      }
      if (col?.process_produce_base_project_instead) {
        return col?.process_base_project?.name || '-';
      }
      return col?.process_product?.name || '-';
    },
  },
  {
    label: 'จำนวน',
    value: (col) => col?.process_quantity,
  },
  {
    label: 'ราคาต่อหน่วย',
    value: (col) => col?.process_price_per_unit || '-',
  },
  {
    label: 'มูลค่า',
    value: (col) =>
      (col?.process_price_per_unit || 0) * (col?.process_quantity || 0),
  },
  {
    label: 'ราคาสินค้า',
    value: (col) => enableWhenColPrimary(col, col?.product_price),
  },
  {
    label: 'ค่าธรรมเนียมการผลิตด่วย',
    value: (col) => enableWhenColPrimary(col, col?.express_fee || 0),
  },
  {
    label: 'ส่วนลด',
    value: (col) => enableWhenColPrimary(col, col?.discount || 0),
  },
  {
    label: 'ราคาสุทธิ',
    value: (col) => enableWhenColPrimary(col, col?.total_price),
  },
];

export default { columns, columnWithProcess };
