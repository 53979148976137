import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { DesktopDatepicker, DesktopDateTimepicker } from '../../DatePicker';
import ManufacturingOrderSelector from '../../Inputs/ManufacturingOrderSelecter';

import ProductAndMaterialSelectForm from './ProductAndMaterialSelectorForm';

dayjs.extend(BuddhistEra);

/**

 * @typedef {object} ManufactoringOrderFormProp
 * @property {object} control React Hook Form Control Object
 * @property {object} manufacturingOrder คำสั่งผลิตที่โยนมาเป็น Default Value
 * @property {function} watch watch function ของ React Hook Form ที่เอาไว้ติดตามความเคลื่อนไหวของ
 * Field ต่างๆ ในฟอร์ม
 * @property {object} products GET Object ของรายการ Product
 * @property {object} material GET Object ของรายการวัตถุดิบ
 * @property {object} customer GET Object ของลูกค้า
 * @property {object} information GET Object ของ Info ระบบทั้งหมด
 * @property {object} paymentType GET Object ของ Payment Type
 * ซึ่งเอาไว้ใช้เวลาเลือกจะใส่ช่องทางชำระเงิน
 * @property {object} customerType GET Object ของ Customer Type เอาไว้เวลาต้องการสรา้ง
 * Customer ใหม่ในการสร้างคำสั่งผลิตเลย
 * @property {object} processTemplate GET Object ของสูตรการผลิต
 * @property {funcion} setValue setValue function ใน React hook form
 * @property {object} machine GET Object ของเครื่องจักรทั้งหมด
 * @property {boolean} isReady พร้อมสำหรับแสดงผลฟอร์มหรือยัง
 * @property {object} bomTemplate GET Objecct ของ BOM Template
 * @property {object} baseProject GET Object ของโปรเจกต์ / สินค้าพื้นฐาน
 *
 * @function ManufacturingOrderForm
 * @description ฟอร์มสำหรับการสร้าง Manufacturing Order หรือ คำสั่งผลิต
 * ฟอร์มหลัก แล้วจะยังมีฟอร์มย่อยๆ อีก
 * @memberof MMS
 * @param {ManufactoringOrderFormProp} props
 * @returns {React.Component} React Component
 */

export const ManufacturingOrderForm = ({
  control,
  errors,
  manufacturingOrder,
  watch,
  products,
  materials,
  customer,
  information,
  paymentType,
  customerType,
  processTemplate,
  setValue,
  machine,
  isReady,
  bomTemplate,
  baseProject,
}) => {
  const [reload, setReload] = useState(false);
  const {
    setting: { mms: mmsSetting },
  } = information;
  const renderAttachCustomer = () => (
    <div>
      <div className="flex flex-row flex-wrap ">
        <div className="w-full py-1 ">
          <Controller
            name="customer"
            control={control}
            defaultValue={manufacturingOrder?.customer || customer?.rows?.[0]}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                options={_.map(
                  customer?.rows,
                  (eachCustomer, customerIndex) => ({
                    ...eachCustomer,
                    index: customerIndex,
                  }),
                )}
                getOptionLabel={(option) =>
                  `${option?.index || ''} ${option?.type_code || ''} | ${
                    option?.name
                  }`
                }
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField {...params} label="เลือกลูกค้า" required />
                )}
              />
            )}
          />
        </div>
      </div>
    </div>
  );

  const renderCreateNewCustomer = () => (
    <div className="flex flex-wrap">
      <div className="w-full p-1">
        <Controller
          control={control}
          name="customer.name"
          render={({ field }) => (
            <TextField {...field} size="small" label="ชื่อ" fullWidth />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                หมวดหมู่
              </InputLabel>
              <Select {...field} label="หมวดหมู่" size={'small'} fullWidth>
                {_.size(customerType?.rows) ? (
                  _.map(customerType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.organization_type'}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทธุรกิจ
              </InputLabel>
              <Select {...field} label="ประเภทธุรกิจ" size={'small'} fullWidth>
                <MenuItem value="บุคคลธรรมดา">บุคคลธรรมดา</MenuItem>
                <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'customer.type_code'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสลูกค้า / คู่ค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.taxes'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              helperText={errors.taxes && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'customer.phone_number'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>{' '}
      <div className="w-full px-1 py-1">
        <Controller
          name={'customer.address'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              rows={3}
              multiline={true}
              fullWidth
              size={'small'}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
  // Components Rendering
  const renderOrderInfo = () => (
    <div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/3">
              <div className="my-2 font-semibold">วันที่สั่งผลิต / วางแผน</div>
              <div className="flex flex-row flex-wrap ">
                <div className="w-full  px-1 py-1 mt-1">
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue={
                      manufacturingOrder
                        ? dayjs(manufacturingOrder?.start_date)
                            .locale('th')
                            .format('YYYY-MM-DD')
                        : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
                    }
                    rules={{ required: true }}
                    render={({ field }) =>
                      (mmsSetting?.timing?.showTime ? (
                        <DesktopDateTimepicker
                          label="วันที่สั่งผลิต / วางแผน"
                          {...field}
                          size="small"
                          fullWidth
                        />
                      ) : (
                        <DesktopDatepicker
                          label="วันที่สั่งผลิต / วางแผน"
                          {...field}
                          size="small"
                          fullWidth
                        />
                      ))
                    }
                  />
                </div>{' '}
                {mmsSetting?.usingAsDefault?.showExpectedDate && (
                  <div className="w-full  px-1 py-1">
                    <Controller
                      name="expected_date"
                      control={control}
                      defaultValue={
                        manufacturingOrder
                          ? dayjs(manufacturingOrder?.expected_date)
                              .locale('th')
                              .format('YYYY-MM-DD')
                          : dayjs(new Date()).locale('th').format('YYYY-MM-DD')
                      }
                      rules={{ required: false }}
                      render={({ field }) =>
                        (mmsSetting?.timing?.showTime ? (
                          <DesktopDateTimepicker
                            label="วันที่กำหนดจัดส่ง"
                            {...field}
                            size="small"
                            fullWidth
                          />
                        ) : (
                          <DesktopDatepicker
                            label="วันที่กำหนดจัดส่ง"
                            {...field}
                            size="small"
                            fullWidth
                          />
                        ))
                      }
                    />
                  </div>
                )}{' '}
                <div className="lg:w-1/2 w-full px-1 py-1">
                  <Controller
                    name="attachCustomer"
                    control={control}
                    value={_.isObject(manufacturingOrder?.customer) || false}
                    render={({ field }) => (
                      <FormControl disabled={watch('attachOrder')}>
                        <FormControlLabel
                          label="ระบุลูกค้า"
                          control={
                            <Checkbox
                              {...field}
                              defaultChecked={field.value}
                            />
                          }
                        />
                      </FormControl>
                    )}
                  />
                </div>
                {watch('attachCustomer') === true ? (
                  <div className="lg:w-1/2 w-full px-1 py-1">
                    <Controller
                      name="createNewCustomer"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <FormControl disabled={!watch('attachCustomer')}>
                          <FormControlLabel
                            label="สร้างลูกค้าใหม่"
                            control={
                              <Checkbox {...field} checked={field?.value} />
                            }
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="w-full px-1 py-1">
                  {watch('attachCustomer') && !watch('createNewCustomer')
                    ? renderAttachCustomer()
                    : ''}
                </div>
                <div className="w-full p-1">
                  {watch('attachCustomer') && watch('createNewCustomer')
                    ? renderCreateNewCustomer()
                    : ''}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/3">
              <div className="flex flex-row flex-wrap ">
                {information?.setting?.mms?.manufacturingOrder
                  ?.showQuotation && !watch('quotation')?._id && (
                  <div className="w-full  px-1 py-2">
                    <Controller
                      name="quotation_number"
                      control={control}
                      defaultValue={manufacturingOrder?.quotation_number}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size={'small'}
                          label="เลขที่ใบเสนอราคา"
                          disabled={!!watch('quotation')?._id}
                          helperText={
                            errors.quotation_number && 'กรุณาใส่ข้อมูล'
                          }
                        />
                      )}
                    />
                  </div>
                )}{' '}
                {information?.setting?.mms?.manufacturingOrder
                  ?.showQuotation && watch('quotation')?._id && <div className='my-2'>หมายเลขใบเสนอราคา: {watch('quotation_number')}</div>}
                {information?.setting?.mms?.manufacturingOrder?.showPrice && (
                  <div className="w-full  px-1 py-1">
                    <Controller
                      name="discount"
                      control={control}
                      defaultValue={manufacturingOrder?.discount}
                      rules={{ required: false, min: 0 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size={'small'}
                          label="ส่วนลด (บาท)"
                          helperText={
                            errors.quotation_number && 'กรุณาใส่ข้อมูล'
                          }
                        />
                      )}
                    />
                  </div>
                )}{' '}
                {information?.setting?.mms?.manufacturingOrder
                  ?.showPaymentMethod &&
                  !watch('quotation') && (
                    <div className="w-full  px-1 py-1">
                      <Controller
                        name="payment_method"
                        control={control}
                        defaultValue={manufacturingOrder?.payment_method?._id}
                        rules={{ required: false }}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel>ช่องทางการชำระเงิน</InputLabel>
                            <Select
                              {...field}
                              fullWidth
                              size={'small'}
                              label="ช่องทางการชำระเงิน"
                            >
                              {_.map(paymentType?.rows, (row, index) => (
                                <MenuItem key={index} value={row?._id}>
                                  {row?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </div>
                  )}{' '}
                {information?.setting?.mms?.manufacturingOrder
                  ?.showPaymentMethod &&
                  watch('quotation') && (
                    <div className="w-full  px-1 py-1">
                      ช่องทางการชำระเงิน {watch('payment_method_name')}
                    </div>
                  )}
                {watch('express') && (
                  <div className="w-full  px-1 py-1">
                    <Controller
                      name="express_fee"
                      control={control}
                      defaultValue={manufacturingOrder?.express_fee}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ค่าธรรมเนียมการผลิตด่วน"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                )}{' '}
                <div className="text-white text-xs">
                  Payment Method {watch('payment_method')}
                </div>
              </div>
            </div>{' '}
            <div className="w-full lg:w-1/3">
              <div className="flex flex-wrap">
                {information?.setting?.mms?.manufacturingOrder?.showPO && (
                  <div className="w-full px-1 py-1">
                    <Controller
                      name="po_number"
                      control={control}
                      defaultValue={manufacturingOrder?.po_number || ''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="หมายเลขใบสั่งซื้อของลูกค้า (PO)"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                )}
                {information?.setting?.mms?.enableClaimedMO && (
                  <div className="w-full px-1 py-1">
                    <Controller
                      name="is_claimed"
                      control={control}
                      defaultValue={manufacturingOrder?.is_claimed || false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Switch {...field} defaultChecked={field.value} />
                          }
                          label="เป็นใบเคลม"
                        />
                      )}
                    />
                    {watch('is_claimed') && (
                      <ManufacturingOrderSelector
                        control={control}
                        name="claimed_mo"
                      />
                    )}
                  </div>
                )}
                <div className="w-full px-1 py-1">
                  <Controller
                    name="remark"
                    control={control}
                    defaultValue={manufacturingOrder?.remark || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="หมายเหตุ"
                        rows={3}
                        fullWidth
                        size={'small'}
                        multiline={true}
                        helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>{' '}
    </div>
  );

  const renderAttachProduct = () => (
    <div>
      <ProductAndMaterialSelectForm
        errors={errors}
        control={control}
        product={products}
        material={materials?.rows}
        manufacturingOrder={manufacturingOrder}
        information={information}
        watch={watch}
        customer={customer}
        processTemplate={processTemplate}
        setValue={setValue}
        machine={machine}
        isReady={isReady}
        bomTemplate={bomTemplate}
        baseProject={baseProject}
      />
    </div>
  );

  return (
    <div>
      <div className="flex flex-wrap ">
        <div className="w-full">{renderOrderInfo()}</div>
        <div className="w-full py-2">{renderAttachProduct()}</div>
      </div>
    </div>
  );
};

ManufacturingOrderForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  manufacturingOrder: PropTypes.object,
  watch: PropTypes.func,
  products: PropTypes.object,
  materials: PropTypes.object,
  customer: PropTypes.object,
  information: PropTypes.object,
  paymentType: PropTypes.object,
  customerType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  processTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  machine: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  bomTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  baseProject: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
  isReady: PropTypes.bool,
};

export default ManufacturingOrderForm;
