import React, { useState } from 'react';
import { Edit, ExternalLink } from 'react-feather';
import { Link } from 'react-router-dom';
import { moCostCalculation } from '@iarcpsu/emanufac-utils/functions';
import { Alert, AlertTitle, Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter, durationTimeDisplay } from '@/utils/functions';

import 'dayjs/locale/th';

import UpdateRatingDialog from '../../Modals/CRM/UpdateRatingDialog';
import NameBox from '../Common/NameBox';
import RatingTag from '../CRM/RatingTag';

const InfoManufacturingOrder = ({
  manufacturingOrder,
  information,
  handleMOToCancle,
  handleMOToSuccess,
  handleUpdateCustomerRating,
  currentFromMoType,
}) => {
  const {
    setting: { mms: mmsSetting },
  } = information;
  const { timeDurationWord } = durationTimeDisplay;
  const isMOComplete = manufacturingOrder?.completed;
  const isMODeleted = manufacturingOrder?.deleted;
  const [ratingDialogOpen, setRatingDialogOpen] = useState(false);

  console.log('Rating Dialog Open', ratingDialogOpen);

  // คำนวณหาว่าเหลือกี่วันจาก Function ที่เขียนไว้ใน Util
  const calculatedDate = manufacturingOrder?.completed
    ? dayjs(manufacturingOrder.updatedAt)
    : dayjs();

  // แสดงกรณี Manufac Order Success แล้ว
  const renderSuccessBadge = () => {
    if (isMOComplete) {
      return (
        <div className="my-2">
          <Alert severity="success">
            <AlertTitle>
              <strong>การผลิตสำเร็จแล้ว</strong>
            </AlertTitle>
            ทุกงานในคำสั่งผลิตนี้สำเร็จแล้ว
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  // แสดงกรณี Manufac Order ยกเลิก
  const renderDeleteBadge = () => {
    if (isMODeleted) {
      return (
        <div className="my-2">
          <Alert severity="error">
            <AlertTitle>
              <strong>การผลิตถูกยกเลิก</strong>
            </AlertTitle>
            คำสั่งผลิตนี้ถูกยกเลิกไปแล้ว
            {manufacturingOrder?.delete_note
              ? `ด้วยเหตุผล ${manufacturingOrder?.delete_note}`
              : ''}
          </Alert>
        </div>
      );
    }
    return <div></div>;
  };

  const timeReminder = (expectedDate) => {
    const timeDuration = timeDurationWord(
      expectedDate,
      calculatedDate,
      manufacturingOrder?.completed,
    );
    return <div className={timeDuration.cssClass}> {timeDuration.word} </div>;
  };

  const totalPriceList = _.map(manufacturingOrder?.process, (eachProcess) => {
    if (eachProcess?.deleted) return 0;
    return eachProcess?.quantity * eachProcess?.price_per_unit || 0;
  });

  const totalPrice = moCostCalculation(manufacturingOrder);

  const renderSuccessOrRemoveSelect = () => {
    const completedProcesses = _.filter(
      manufacturingOrder?.process,
      (eachProcess) => eachProcess?.completed,
    );
    const deletedProcesses = _.filter(
      manufacturingOrder?.process,
      (eachProcess) => eachProcess?.deleted,
    );
    if (
      !_.isEmpty(completedProcesses) &&
      !_.isEmpty(deletedProcesses) &&
      !manufacturingOrder?.completed &&
      !manufacturingOrder?.deleted
    ) {
      return (
        <div className=" m-2">
          <div className="bg-gray-100 rounded-md p-4">
            <div className="text-center">
              คำสั่งผลิตนี้ประกอบไปด้วยทั้งงานที่สำเร็จ และ งานที่ยกเลิก
              ต้องการตั้งค่าสถานะคำสั่งผลิตเป็นสถานะใด
            </div>
            <div className="flex justify-center gap-2 mt-2">
              <Button
                variant="contained"
                color="error"
                onClick={() =>
                  handleMOToCancle({
                    manufacturingOrderId: manufacturingOrder?._id,
                  })
                }
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() =>
                  handleMOToSuccess({
                    manufacturingOrderId: manufacturingOrder?._id,
                  })
                }
              >
                สำเร็จ
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <div></div>;
  };

  const findTheOriginalMONumber = (currentNumber) => {
    try {
      const splitedNumber = _.split(currentNumber, '-');
      if (splitedNumber.length === 1) {
        console.log('This is the original MO');
        return splitedNumber[0];
      }

      const lastSplitted = _.last(splitedNumber);
      if (lastSplitted === '1') {
        console.log('This is one child step');
        return splitedNumber[0];
      }

      const previousNumber = parseFloat(lastSplitted) - 1;
      const previousMO = `${splitedNumber[0]}-${previousNumber}`;
      return previousMO;
    } catch (error) {
      console.error('Error on Find Original MO Number', error);
      return currentNumber;
    }
  };

  const renderWaitForRecieve = () => {
    if (
      manufacturingOrder?.is_wait_for_recieve &&
      !manufacturingOrder?.completed &&
      !manufacturingOrder?.deleted
    ) {
      return (
        <div className=" m-2">
          <div className="bg-gray-100 rounded-md p-4">
            <div className="text-center">คำสั่งนี้กำลังรอลูกค้ากำลังรับงาน</div>
            <div className="flex justify-center">
              <Link
                to={`/mms/manufacturing-orders/recieve/${manufacturingOrder?._id}`}
              >
                <Button variant="contained" color="warning">
                  รับงานโดยลูกค้า
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="m-2">
        <div className="font-semibold">การรับสินค้าของลูกค้า</div>
      </div>
    );
  };

  const renderDisplayMode = () => (
    <div className="flex flex-wrap w-full ">
      <div className="w-full text-lg py-2 px-2 font-semibold font-display ">
        คำสั่งผลิต {manufacturingOrder?.prefix || ''}
        {manufacturingOrder?.running_number}{' '}
        {manufacturingOrder?.is_claimed && (
          <span className="px-2 rounded-md bg-gray-200 font-normal text-sm">
            ใบเคลม
          </span>
        )}
      </div>
      {manufacturingOrder?.customer &&
        !_.isEmpty(manufacturingOrder?.customer) && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            ลูกค้า
          </div>
        )}
      {manufacturingOrder?.customer &&
        !_.isEmpty(manufacturingOrder?.customer) && (
          <div className="w-full md:w-5/6 py-2 px-2">
            <Link
              className="text-gray-700 hover:text-gray-600 hover:underline"
              to={`/crm/customer/mini-detail/${
                manufacturingOrder?.order?.customer?._id ||
                manufacturingOrder?.customer?._id
              }`}
            >
              {manufacturingOrder?.order?.customer?.type_code ||
                manufacturingOrder?.customer?.type_code}{' '}
              -{' '}
              {manufacturingOrder?.order?.customer?.name ||
                manufacturingOrder?.customer?.name}{' '}
            </Link>
            <RatingTag
              allowUpdate
              customer={
                manufacturingOrder?.order?.customer ||
                manufacturingOrder?.customer
              }
              asBox
              showEvenIsEmpty
              handleUpdateRating={() => {
                console.log('Set Rating Dialog To True');
                setRatingDialogOpen(true);
              }}
            />
            <br />
          </div>
        )}
      <div className="w-full md:w-1/6 py-1 md:py-2 px-2 font-semibold font-display ">
        ผู้สั่งผลิต
      </div>
      <div className="w-full md:w-5/6 py-1 md:py-2 px-2">
        <NameBox user={manufacturingOrder?.assigner} showDepartment={false} />
      </div>
      <div className="w-full md:w-1/6 py-1 pt-4 md:py-2 px-2 font-semibold font-display ">
        วันเริ่มการผลิต
      </div>
      <div className="w-full md:w-2/6 py-1 md:py-2 px-2">
        <div className="flex gap-2 md:block">
          {dayjs(manufacturingOrder?.start_date)
            .locale('th')
            .format(
              mmsSetting?.timing?.showTime
                ? 'D MMMM BBBB HH:mm'
                : 'D MMMM BBBB',
            )}
        </div>
      </div>
      {mmsSetting?.usingAsDefault?.showExpectedDate && (
        <div className="w-full md:w-1/6 py-1  md:py-2 pt-4 md:pt-2 px-2 font-semibold font-display ">
          วันกำหนดส่ง
        </div>
      )}
      {mmsSetting?.usingAsDefault?.showExpectedDate && (
        <div className="flex flex-wrap w-full md:w-2/6 py-1 md:py-2 px-2 ">
          {/* <div className=" gap-2 md:block"> */}
          <div className="mr-4">
            {dayjs(manufacturingOrder?.expected_date)
              .locale('th')
              .format(
                mmsSetting?.timing?.showTime
                  ? 'D MMMM BBBB HH:mm'
                  : 'D MMMM BBBB',
              )}
          </div>
          <div>
            {!isMOComplete &&
              !isMODeleted &&
              timeReminder(manufacturingOrder?.expected_date)}
          </div>
        </div>
      )}
      {information?.setting?.mms?.manufacturingOrder?.showQuotation &&
        (manufacturingOrder?.quotation_number ||
          (manufacturingOrder?.quotation &&
            !_.isEmpty(manufacturingOrder?.quotation))) && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            เลขที่ใบเสนอราคา
          </div>
        )}
      {information?.setting?.mms?.manufacturingOrder?.showQuotation &&
        (manufacturingOrder?.quotation_number ||
          (manufacturingOrder?.quotation &&
            !_.isEmpty(manufacturingOrder?.quotation))) && (
          <div className="w-full md:w-2/6 py-2 px-2  flex gap-1 items-center">
            {manufacturingOrder?.quotation_number}
            <span>
              {manufacturingOrder?.quotation && (
                <Link
                  to={`/spm/quotation/detail/${
                    manufacturingOrder?.quotation?._id ||
                    manufacturingOrder?.quotation
                  }`}
                >
                  <ExternalLink size={16} />
                </Link>
              )}
            </span>
          </div>
        )}
      {manufacturingOrder?.is_internal_deposit && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          เลขที่ใบเบิกใช้ภายใน
        </div>
      )}
      {manufacturingOrder?.is_internal_deposit && (
        <div className="w-full md:w-2/6 py-2 px-2  flex gap-1 items-center">
          {manufacturingOrder?.internal_deposit_number}
          <span>
            {manufacturingOrder?.internal_deposit && (
              <Link
                to={`/spm/internal-deposit/detail/${
                  manufacturingOrder?.internal_deposit?._id ||
                  manufacturingOrder?.internal_deposit
                }`}
              >
                <ExternalLink size={16} />
              </Link>
            )}
          </span>
        </div>
      )}
      {information?.setting?.mms?.manufacturingOrder?.showPaymentMethod &&
        manufacturingOrder?.payment_method && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            วิธีการชำระเงิน
          </div>
        )}
      {information?.setting?.mms?.manufacturingOrder?.showPaymentMethod &&
        manufacturingOrder?.payment_method && (
          <div className="w-full md:w-2/6 py-2 px-2">
            {manufacturingOrder?.payment_method?.name}
          </div>
        )}
      {(information?.setting?.mms?.manufacturingOrder?.showPrice &&
        manufacturingOrder?.discount && (
          <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
            ส่วนลด
          </div>
        )) ||
        ''}
      {(information?.setting?.mms?.manufacturingOrder?.showPrice &&
        manufacturingOrder?.discount &&
        manufacturingOrder?.discount !== 0 && (
          <div className="w-full md:w-2/6 py-2 px-2">
            {currencyFormatter.format(manufacturingOrder?.discount)} บาท
          </div>
        )) ||
        ''}
      {information?.setting?.mms?.manufacturingOrder?.showPO && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          หมายเลขสั่งซื่อของลูกค้า (หมายเลข PO)
        </div>
      )}
      {information?.setting?.mms?.manufacturingOrder?.showPO && (
        <div className="w-full md:w-2/6 py-2 px-2">
          {manufacturingOrder?.po_number || '-'}
        </div>
      )}{' '}
      {(information?.setting?.mms?.manufacturingOrder?.addDeposit && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          มัดจำ
        </div>
      )) ||
        ''}
      {(information?.setting?.mms?.manufacturingOrder?.addDeposit && (
        <div className="w-full md:w-2/6 py-2 px-2">
          {manufacturingOrder?.deposit?.is_deposit ? (
            <div className="flex items-center gap-1">
              <span>
                {currencyFormatter.format(manufacturingOrder?.deposit?.amount)}{' '}
                บาท{' '}
              </span>
              <Link
                to={`/mms/manufacturing-orders/deposit/edit/${manufacturingOrder?._id}`}
              >
                <Edit size={16} />
              </Link>
            </div>
          ) : (
            <div>
              ยังไม่ได้มัดจำ{' '}
              <Link
                to={`/mms/manufacturing-orders/deposit/${manufacturingOrder?._id}`}
              >
                <button className="rounded-md bg-blue-600 px-2 py-1 hover:bg-blue-700 text-white">
                  มัดจำ
                </button>
              </Link>
            </div>
          )}
        </div>
      )) ||
        ''}
      {manufacturingOrder?.is_claimed && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          คำลั่งผลิตเดิม
        </div>
      )}
      {manufacturingOrder?.is_claimed && (
        <div className="w-full md:w-2/6 py-2 px-2">
          <Link
            to={`/mms/manufacturing-orders-${currentFromMoType}/view/${manufacturingOrder?.claimed_mo}`}
          >
            <div className="flex items-center gap-1">
              <span>
                {manufacturingOrder?.prefix || ''}
                {findTheOriginalMONumber(manufacturingOrder?.running_number)}
              </span>
              <ExternalLink size={16} />
            </div>
          </Link>
        </div>
      )}{' '}
      {manufacturingOrder?.express && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          งานด่วน
        </div>
      )}
      {manufacturingOrder?.express && (
        <div className="w-full md:w-2/6 py-2 px-2">
          ค่าธรรมเนียม
          {currencyFormatter.format(manufacturingOrder?.express_fee)} บาท
        </div>
      )}{' '}
      {totalPrice !== 0 && (
        <div className="w-full md:w-1/6 py-2 px-2 font-semibold font-display ">
          มูลค่ารวม
        </div>
      )}
      {totalPrice !== 0 && (
        <div className="w-full md:w-2/6 py-2 px-2">
          {currencyFormatter.format(totalPrice)} บาท{' '}
          {(manufacturingOrder?.discount &&
            manufacturingOrder?.discount !== 0) ||
          (manufacturingOrder?.express_fee &&
            manufacturingOrder?.express_fee !== 0)
            ? `(ราคาเดิม ${currencyFormatter.format(
                _.sum(totalPriceList),
              )} บาท)`
            : ''}
        </div>
      )}
      {!_.isEmpty(manufacturingOrder?.claiming_mo) && (
        <div className="w-full  px-2 pt-2 font-semibold font-display ">
          คำสั่งผลิตเคลม
        </div>
      )}
      {!_.isEmpty(manufacturingOrder?.claiming_mo) && (
        <div className="w-full py-1 px-2 lg:pr-6">
          <ul className="list-disc list-inside">
            {_.map(manufacturingOrder?.claiming_mo, (eachClaimingMO, idx) => (
              <Link
                key={idx}
                to={`/mms/manufacturing-orders-${currentFromMoType}/view/${eachClaimingMO?._id}`}
              >
                <div className="flex items-center gap-1">
                  <span>
                    {manufacturingOrder?.prefix || ''}
                    {eachClaimingMO?.running_number}
                  </span>
                  <ExternalLink size={16} />
                </div>
              </Link>
            ))}
          </ul>
        </div>
      )}
      <div className="w-full">
        <div className="w-full px-2 pt-2 font-semibold font-display ">
          รายละเอียด / หมายเหตุ
        </div>
        <div className="w-full py-1 px-2 lg:pr-6">
          {manufacturingOrder?.remark || '-'}
        </div>
      </div>
      <div className="w-full">
        {manufacturingOrder?.process &&
          renderSuccessBadge(manufacturingOrder?.expected_date)}
        {manufacturingOrder?.process && renderDeleteBadge()}
      </div>
      <div className="w-full">{renderSuccessOrRemoveSelect()}</div>
      <div className="w-full">
        {information?.setting?.mms?.enableRecieveWorkBeforeEndMO &&
          renderWaitForRecieve()}
      </div>
      {!_.isEmpty(information?.setting?.mms?.customReport) && (
        <div className="px-2 w-full font-semibold font-display py-1">
          รายงาน
        </div>
      )}
      <div className="px-2 flex flex-wrap gap-2">
        {_.map(
          _.filter(
            information?.setting?.mms?.customReport,
            (each) => each?.is_process_level === false,
          ),
          (eachCReport, idx) => (
            <Link
              to={{
                pathname: '/mms/manufacturing-orders/report',
                state: {
                  manufacturingOrder,
                  report: eachCReport,
                },
              }}
              key={idx}
            >
              <Button variant="outlined">{eachCReport?.name}</Button>
            </Link>
          ),
        )}
      </div>
    </div>
  );

  const renderRatingDialog = () => (
    <UpdateRatingDialog
      customer={
        manufacturingOrder?.order?.customer || manufacturingOrder?.customer
      }
      handleUpdate={(customerId, newRating) => {
        // setRatingDialogOpen(false);
        handleUpdateCustomerRating(customerId, newRating);
      }}
      open={ratingDialogOpen}
      handleCloseDialog={() => setRatingDialogOpen(false)}
    />
  );

  return (
    <div>
      {renderRatingDialog()}
      <Card className=" p-4 pb-6">{renderDisplayMode()} </Card>
    </div>
  );
};

InfoManufacturingOrder.propTypes = {
  manufacturingOrder: PropTypes.object,
  me: PropTypes.object,
  information: PropTypes.object,
  handleToEditorMode: PropTypes.func,
  handleMOToCancle: PropTypes.func,
  handleMOToSuccess: PropTypes.func,
  showStatus: PropTypes.bool,
};

export default InfoManufacturingOrder;
