import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BackButton,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, Checkbox, FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '@/redux/actions';

import 'dayjs/locale/th';

dayjs.extend(BuddhistCalendar);

const SPMModuleSetting = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const { control, handleSubmit } = useForm({
    defaultValues: { setting: { spm: information?.setting?.spm } },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            setting: {
              ...information.setting,
              spm: {
                ...data.setting.spm,
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>
          <Card className="p-4">
            <div className="flex flex-wrap w-full ">
              <div className="w-full py-2 px-2 font-semibold font-display ">
                ตั้งค่าใบเสนอราคา
              </div>
              <div className="w-1/2 py-2 px-2">
                ส่วนประกอบเพิ่มเติมในใบเสนอราคา
              </div>
              <div className="w-1/2 py-2 px-2">
                <div className="flex flex-col w-full">
                  <Controller
                    control={control}
                    name="setting.spm.quotation.display_electronic_signature"
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox defaultChecked={field.value} />}
                        label="แสดงลายเซนต์อิเล็กทรอนิกส์"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="setting.spm.quotation.display_sales_name"
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox defaultChecked={field.value} />}
                        label="แสดงชื่อพนักงานขาย"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="setting.spm.quotation.display_company_stamp"
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        control={<Checkbox defaultChecked={field.value} />}
                        label="แสดงตราบริษัท"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SPMModuleSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SPMModuleSetting.defaultProps = {
  title: '',
  subtitle: '',
};
export default SPMModuleSetting;
