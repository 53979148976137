import _ from 'lodash';

const moCostCalculation = (manufacturingOrder) => {
  const totalPriceList = _.map(manufacturingOrder?.process, (eachProcess) => {
    if (eachProcess?.deleted) return 0;
    return eachProcess?.quantity * eachProcess?.price_per_unit || 0;
  });

  const totalPrice =
    _.sum(totalPriceList) -
    (manufacturingOrder?.discount || 0) +
    (manufacturingOrder?.express_fee || 0);
  return totalPrice;
};

export default moCostCalculation;
