import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  Error,
  Loading,
  LoadingDialog,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { MQ_TASK } from '@iarcpsu/emanufac-constant';
import {
  currencyFormatter,
  moCostCalculation,
} from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function ManufacturingOrderDeposit
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ManufacturingOrderDeposit({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const { control, watch, handleSubmit } = useForm();
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const totalPrice = moCostCalculation(manufacturingOrder);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.manufacturingOrderGet(params.id, {}));
    } catch (error) {
      dispatch(actions.manufacturingOrderError());
    }
  };

  const handleOnSubmit = async (data) => {
    const depositData = {
      is_deposit: true,
      amount:
        parseFloat(data.add_amount || 0) +
        (manufacturingOrder?.deposit?.amount || 0),
      deposit_date: data.deposit.deposit_date,
    };
    const payload = {
      deposit: depositData,
      isUpdateDeposit: true,
      employee: me?.userData?._id,
      referenceNumber,
    };
    console.log('Deposit Payload', payload);
    try {
      await dispatch(actions.manufacturingOrderPut(params.id, payload));
      setIsDialogLoadingOn(true);
    } catch (error) {
      Swal.fire({
        title: 'บันทึกมัดจำไม่สำเร็จ',
        icon: 'error',
        text: error.message,
      });
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      Swal.fire({
        title: 'บันทึกมัดจำไม่สำเร็จ',
        icon: 'error',
        text: args.message,
      });
    },
    onSuccess: (args) => {
      setIsDialogLoadingOn(false);
      Swal.fire({
        title: 'บันทึกมัดจำสำเร็จ',
        icon: 'success',
      }).then(() => {
        history.goBack();
      });
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.UPDATE_MANUFAC_ORDER.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />
        <LoadingDialog
          isLoading={isDialogLoadingOn}
          label="กำลังอัพเดทข้อมูลมัดจำ"
        />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Card>
              <CardContent>
                <div className="my-1 font-semibold">มัดจำของคำสั้งผลิต</div>
                <div className="my-2">
                  <div className="my-1">วันที่มัดจำ</div>
                  <div className="my-2">
                    <Controller
                      control={control}
                      name="deposit.deposit_date"
                      defaultValue={manufacturingOrder?.deposit?.deposit_date}
                      render={({ field }) => (
                        <DesktopDatepicker
                          {...field}
                          value={field.value || new Date()}
                          fullWidth
                          label="วันที่มัดจำ"
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="my-1 mt-2 font-semibold">
                    รายละเอียดการมัดจำ
                  </div>
                </div>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>ราคาทั้งหมด </TableCell>
                        <TableCell>
                          {currencyFormatter.format(totalPrice || 0)}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ชำระค่ามัดจำไปแล้ว </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            manufacturingOrder?.deposit?.amount || 0,
                          )}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>{' '}
                      <TableRow>
                        <TableCell> ชำระเงินมัดจำเพิ่ม </TableCell>
                        <TableCell>
                          <Controller
                            name="add_amount"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="ชำระเงินเพิ่ม"
                                size="small"
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>{' '}
                      <TableRow>
                        <TableCell> รวมเงินมัดจำ </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            parseFloat(watch('add_amount') || 0) +
                              (manufacturingOrder?.deposit?.amount || 0),
                          )}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>{' '}
            <div className="flex justify-end my-1">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

ManufacturingOrderDeposit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingOrderDeposit.defaultProps = {
  title: '',
  subtitle: '',
};
