import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NotAuthorized } from '@iarcpsu/emanufac-components/src/components/Error';

import accessRight from '../../utils/functions/accessRight';
import {
  Departments,
  EditDepartment,
  EditRoleType,
  HRMSModuleSetting,
  RoleType,
} from '../../views/HRMS';

export function SettingSPM() {
  const module = 'HRMS';
  const prefix = '/setting/hrms';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/department`}>
          <Departments title="จัดการแผนก" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/department/edit/:id`}>
          <EditDepartment title="แก้ไขแผนก" subtitle={name} />
        </Route>{' '}
        <Route exact path={`${prefix}/module-setting`}>
          <HRMSModuleSetting title="ตั้งค่าระบบทรัพยากรบุคคล" subtitle={name} />
        </Route>
        {accessRight(me, module, 2, availableModule) && (
          <Switch>
            <Route exact path={`${prefix}/role-types`}>
              <RoleType title="จัดการบทบาท" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/role-types/edit/:id`}>
              <EditRoleType title="แก้ไขบทบาท" subtitle={name} />
            </Route>
          </Switch>
        )}
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingSPM;
