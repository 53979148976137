import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  Error,
  NotFound,
} from '@iarcpsu/emanufac-components/src/components/Error';
import { MainFooter } from '@iarcpsu/emanufac-components/src/components/Footers';
import { Loading } from '@iarcpsu/emanufac-components/src/components/Loading';
import { HomeNavbar } from '@iarcpsu/emanufac-components/src/components/Navbars';
import { MainSidebar } from '@iarcpsu/emanufac-components/src/components/Sidebar';

import * as actions from '../redux/actions';
import { EmployeeSignature } from '../views/HRMS';
import {
  EditPassword,
  EditPersonalProfile,
  Notify,
  NotifyRedirect,
  PersonalProfile,
  ProfilePersonalize,
  ReportProblem,
} from '../views/Profile';

export function Profile() {
  const prefix = '/profile';
  const name = 'โปรไฟล์';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: Profile');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData || me?.isLoading) {
    return <Loading />;
  }

  if (!me.isLoading && !me.isCompleted) {
    return <Error message="ไม่สามารถโหลดข้อมูลได้" />;
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <meta charSet="utf-8" />
        <title>โปรไฟล์ - {information?.name || 'E-Manufac'}</title>
      </Helmet>
      <MainSidebar
        onMobileClose={handleOnMobileNavClose}
        openMobile={isMobileNavOpen}
        me={me}
      />
      <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
      <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
        <div className="py-4">
          <Switch>
            <Redirect exact from={`${prefix}`} to={`${prefix}/main`} />
            {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
            <Route exact path={`${prefix}/main`}>
              <PersonalProfile title="โปรไฟล์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/edit`}>
              <EditPersonalProfile title="แก้ไขโปรไฟล์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/notify/redirect`}>
              <NotifyRedirect title="ลงทะเบียนการแจ้งเตือน" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/notify`}>
              <Notify title="จัดการการแจ้งเตือน" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/edit-password`}>
              <EditPassword title="แก้ไขรหัสผ่าน" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/report-problem`}>
              <ReportProblem title="แจ้งปัญหา" subtitle={name} />
            </Route>{' '}
            <Route exact path={`${prefix}/signature/:id`}>
              <EmployeeSignature title="จัดการลายเซนต์" subtitle={name} />
            </Route>
            <Route exact path={`${prefix}/personalize`}>
              <ProfilePersonalize
                title="ปรับแต่งหน้าตาการใช้งาน"
                subtitle={name}
              />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <div className="lg:ml-64">
        <MainFooter />
      </div>
    </div>
  );
}

export default Profile;
