/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BackButton,
  Error,
  Loading,
  LoadingDialog,
  ManufacturingOrderForm,
  MOFromTemplateLoadingForm,
  SocketIOLoading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { api, app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';
import { useQuery } from '@/utils/functions';
import { MMS as MMSMiddleWare } from '@/utils/middleware';
/**
 * @function CreateManufacturingOrder
 * @memberof MMS/ManufacturingOrder
 * @description หน้าสำหรับสร้างคำสั่งผลิต มีการใช้ฟอร์มคำสั่งผลิตที่มีเงื่อนไขมากมายอยู่ในนั้น
 * ตามเงื่อนไขของ Setting หลังจาก Submit หน้านี้แล้วระบบจะ Fetch คำสั่งผลิตที่ได้มาอัตโนมัติ
 * จากนั้นจะ Redirect ไปหน้า Assign งานให้พนักงาน
 */
function CreateManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const browserQuery = useQuery();
  const location = useLocation();

  const products = useSelector((state) => state.product);
  const materials = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const paymentType = useSelector((state) => state.paymentType);
  const information = useSelector((state) => state.information);
  const customerType = useSelector((state) => state.customerType);
  const processTemplate = useSelector((state) => state.processTemplate);
  const machine = useSelector((state) => state.machine);
  const me = useSelector((state) => state.me);
  const moTemplate = useSelector((state) => state.moTemplate);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const baseProject = useSelector((state) => state.baseProject);
  const [isReady, setIsReady] = useState(true);
  const [dialogLoading, setIsDialogLoadingOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [createdStatus, setCreatedStatus] = useState('');
  const [quotaionLoading, setQuotationLoading] = useState(false);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      processes: [
        {
          product: products.rows && products.rows[0],
          quantity: 1,
          materials: [
            { materials: materials.rows && materials.rows[0], begin: 1 },
          ],
          noQuantity: false,
          createFromTemplate:
            information?.setting?.mms?.usingAsDefault?.usingFormular,
        },
      ],
    },
  });

  useEffect(() => {
    if (information?.setting?.mms?.usingAsDefault?.usingFormular) {
      setValue(
        'processes[0].createFromTemplate',
        information?.setting?.mms?.usingAsDefault?.usingFormular,
      );
    }

    return () => {};
  }, [information]);

  const query = gql`
    query findProcessTemplates(
      $processTemplateInput: ProcessTemplateInput
      $machineinput: MachineInput
      $moTemplateInput: MOTemplateInput
      $bomTemplateInput: BomTemplateInput
      $baseProjectInput: BaseProjectInput
    ) {
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
          steps {
            name
            materials {
              material {
                _id
                name
                type_code
              }
            }
          }
        }
      }
      findMachines(input: $machineinput) {
        rows {
          _id
          name
        }
      }
      findMOTemplates(input: $moTemplateInput) {
        rows {
          _id
          name
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        total
        rows {
          _id
          name
          tag
          bill_of_materials {
            amount
            tag
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
      }
      findBaseProjects(input: $baseProjectInput) {
        rows {
          _id
          type_code
          name
          unit
          attach_process_template
          attach_bom_template
          process_template {
            _id
            name
          }
          bom_template {
            _id
            name
          }
        }
      }
    }
  `;

  const getNewMONumber = (oldNumber) => {
    const oldNumberArray = oldNumber.split('-');
    try {
      if (oldNumberArray[1]) {
        const newReviseNumber = parseInt(oldNumberArray[1], 10) + 1;
        const newQuotationNumber = `${oldNumberArray[0]}-${newReviseNumber}`;
        return newQuotationNumber;
      }
      return `${oldNumber}-1`;
    } catch (error) {
      return `${oldNumber}-1`;
    }
  };
  const onSubmit = async (data) => {
    try {
      if (!_.size(data.processes)) {
        alert('กรุณาเพิ่มรายการอย่างน้อย 1 รายการ');
        return;
      }
      setIsDialogLoadingOn(true);
      const newProcesses = _.map(data.processes, (_process) => ({
        ..._process,
        quantity: _process?.noQuantity === true ? 0 : _process?.quantity,
        produce_base_project_instead:
          information?.setting?.mms?.allowUseBaseProject,
        additional_unit: _process.changeUnit
          ? _process.additional_unit
          : _process?.product?._unit,
        product: information?.setting?.mms?.allowUseBaseProject
          ? null
          : _process?.product,
      }));
      const payload = {
        ...data,
        processes: newProcesses,
        assigner: me?.userData?._id,
        employeeId: me?.userData?._id,
      };

      if (payload?.is_claimed) {
        const runningNo = getNewMONumber(payload?.claimed_mo?.running_number);
        payload.running_number = runningNo;
      }
      console.log('Payload', payload);
      console.log(
        'Create Manufacturing Order Payload',
        MMSMiddleWare.ManufacturingOrder(payload),
      );

      // Waiting Socket
      // TODO: Implatemented

      await dispatch(
        actions.manufacturingOrderCreate(
          MMSMiddleWare.ManufacturingOrder({ ...payload, referenceNumber }),
        ),
      );

      // setIsDialogLoadingOn(false);
      // setCreated(true);
    } catch (error) {
      window.alert(`ไม่สามารถสร้างคำสั่งผลิตได้ ${error?.message}`);
      console.error('Cannot Create Manufacturing Order', error);
    }
  };

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
    onSuccess: (args) => {
      setIsDialogLoadingOn(false);
      if (information?.setting?.mms?.skipAssignmentPage) {
        if (information?.setting?.mms?.dashboard?.showDashboardC) {
          history.push(
            `/mms/manufacturing-orders-c/view/${args?.payload?._id}`,
          );
        } else {
          history.push(`/mms/manufacturing-orders/view/${args?.payload?._id}`);
        }
      } else {
        history.push(`/mms/manufacturing-orders/assign/${args?.payload?._id}`);
      }
    },
  };

  const fetchMaterials = async () => {
    try {
      await dispatch(
        actions.materialAll({
          name: '',
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
        }),
      );
    } catch (error) {
      console.error('Get Materials Fail', error);
    }
  };

  const fetchProduct = async () => {
    try {
      dispatch(
        actions.productAll({
          name: '',
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          fetchBOM: true,
        }),
      );
    } catch (error) {
      console.error('Fetch Product Fail', error);
    }
  };

  const fetchCustomer = async () => {
    try {
      dispatch(
        actions.customerAll({
          name: '',
          size: appConfig.maxFetchSize,
          isCustomer: true,
        }),
      );
    } catch (error) {
      console.error('Fetch Customers Fail', error);
    }
  };

  const fetchPaymentTransactionType = async () => {
    try {
      dispatch(actions.paymentTypeAll({}));
    } catch (error) {
      console.error('Fetch Payment Transaction Type Fail', error);
    }
  };

  const fetchCustomerType = async () => {
    try {
      dispatch(actions.customerTypeAll({}));
    } catch (error) {
      console.error('Fetch Customer Types Fail', error);
    }
  };

  const settingQuotation = async (quotationId) => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/quotation/${quotationId}`,
      );
      setQuotationLoading(false);
      const newProcesses = _.map(data?.processes, (_process) => {
        const foundProduct = _.find(products?.rows, {
          _id: _process?.product?._id,
        });
        return {
          ..._process,
          product: foundProduct,
          noQuantity: false,
        };
      });

      console.log('data.processes', newProcesses);
      setValue('attachCustomer', true);
      setValue('processes', []);
      setValue('quotation', data);
      setValue('processes', newProcesses);
      setValue('customer', data?.customer);
      setValue('quotation_number', `${data.prefix}${data?.running_number}`);
      setValue('payment_method', data?.payment_method?._id);
      setValue('payment_method_name', data?.payment_method?.name);

      setValue('discount', data?.discount);
      setValue('express', data?.express);
      setValue('express_fee', data?.express_fee);
      // Swal.fire({
      //   icon: 'success',
      //   title: 'ตั้งค่าจากใบเสนอราคาแล้ว',
      // });
    } catch (error) {
      console.error('Cannot Fetch Quotation', error);
    }
  };

  const settingInternationDeposit = async (internalDepId) => {
    try {
      setIsReady(false);
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/internal-deposit/${internalDepId}`,
      );
      setQuotationLoading(false);
      const newProcesses = _.map(data?.processes, (_process) => {
        const foundProduct = _.find(products?.rows, {
          _id: _process?.product?._id,
        });
        return {
          ..._process,
          product: foundProduct,
          noQuantity: false,
        };
      });
      console.log('data.processes', newProcesses);

      setValue('processes', []);
      setValue('attachCustomer', true);
      setValue('internal_deposit', data);
      setValue('processes', newProcesses);
      setValue('is_internal_deposit', true);
      setValue('customer', data?.customer);
      setValue(
        'internal_deposit_number',
        `${data?.prefix}${data?.running_number}`,
      );
      // Swal.fire({
      //   icon: 'success',
      //   title: 'ตั้งค่าจากใบเบิกใช้ภายในแล้ว',
      // });
      setIsReady(true);
    } catch (error) {
      console.error('Cannot Fetch Internal Deposit', error);
    }
  };

  const setManufacturingOrderCliamed = () => {
    try {
      const originalManufacturingOrder = location?.state?.manufacturingOrder;
      const newProcesses = _.map(
        originalManufacturingOrder?.processes,
        (_process) => {
          const foundProduct = _.find(products?.rows, {
            _id: _process?.product?._id,
          });
          return {
            ..._process,
            product: foundProduct,
            noQuantity: false,
          };
        },
      );
      setValue('attachCustomer', true);
      setValue('processes', []);
      setValue(
        'quotation',
        _.isEmpty(originalManufacturingOrder?.quotation)
          ? null
          : originalManufacturingOrder?.quotation,
      );
      setValue(
        'quotation_number',
        originalManufacturingOrder?.quotation_number,
      );
      setValue(
        'internal_deposit',
        originalManufacturingOrder?.internal_deposit,
      );
      setValue('po_number', originalManufacturingOrder?.po_number);
      setValue('processes', newProcesses);
      setValue('customer', originalManufacturingOrder?.customer);
      setValue('is_claimed', true);
      setValue('claimed_mo', originalManufacturingOrder);
      setValue('start_date', originalManufacturingOrder?.start_date);
      setValue('expected_date', originalManufacturingOrder?.expected_date);
      setValue('discount', originalManufacturingOrder?.discount);
      setValue(
        'payment_method',
        originalManufacturingOrder?.payment_method?._id,
      );
    } catch (error) {
      console.error('Cannot Set Manufacturing Order', error);
    }
  };

  const queryDataFromServer = async () => {
    try {
      if (browserQuery.get('quotation')) {
        setQuotationLoading(true);
      }
      if (browserQuery.get('internal-deposit')) {
        setQuotationLoading(true);
      }
      const queryResult = await graphqlClient.request(query, {
        processTemplateInput: { page: 1, size: appConfig.maxFetchSize },
        machineinput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        bomTemplateInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        baseProjectInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const foundedProcessTemplate = queryResult?.findProcessTemplates;
      const foundedMachine = queryResult?.findMachines;
      const foundedMOTemplate = queryResult?.findMOTemplates;
      const foundedBomTemplate = queryResult?.findBomTemplates;
      const foundedBaseProject = queryResult?.findBaseProjects;
      dispatch(actions.processTemplateStateSet(foundedProcessTemplate));
      dispatch(actions.machineStateSet(foundedMachine));
      dispatch(actions.moTemplateStateSet(foundedMOTemplate));
      dispatch(actions.bomTemplateStateSet(foundedBomTemplate));
      dispatch(actions.baseProjectStateSet(foundedBaseProject));
      await fetchProduct();
      await fetchMaterials();
      await fetchCustomer();
      await fetchPaymentTransactionType();
      await fetchCustomerType();
    } catch (error) {
      console.error('Error On Fetching', error);
    }
  };

  useEffect(() => {
    if (
      browserQuery.get('quotation') &&
      products?.rows &&
      !products?.isLoading &&
      materials?.rows &&
      !materials?.isLoading
    ) {
      settingQuotation(browserQuery.get('quotation'));
    }

    if (
      browserQuery.get('internal-deposit') &&
      products?.rows &&
      !products?.isLoading &&
      materials?.rows &&
      !materials?.isLoading
    ) {
      settingInternationDeposit(browserQuery.get('internal-deposit'));
    }

    if (
      location?.state?.isClaimed === true &&
      products?.rows &&
      !products?.isLoading &&
      materials?.rows &&
      !materials?.isLoading
    ) {
      console.log('status set as claimed');
      console.log('location.state', location.state);
      setManufacturingOrderCliamed(browserQuery.get('original'));
    }

    return () => {};
  }, [products, materials, browserQuery]);

  const onLoadDataFromTemplate = (controllerValue, payload) => {
    setIsReady(false);
    setValue(controllerValue, payload);
    console.log('payload value', payload);
    console.log('Watch Data', watch(controllerValue));
    setIsReady(true);
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    materials.isLoading ||
    products.isLoading ||
    customer.isLoading ||
    paymentType.isLoading
  ) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
      </div>
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      {quotaionLoading && <Loading />}
      <SocketIOLoading
        referenceNumber={referenceNumber}
        taskCode={MQ_TASK.INSERT_MANUFAC_ORDER.status_code}
        handleFail={handleSocketIOFunction.onFail}
        handleSuccess={handleSocketIOFunction.onSuccess}
        setStatusMessage={setCreatedStatus}
      />

      <div className="py-2">
        <LoadingDialog
          isLoading={dialogLoading}
          label={`กำลังสร้างคำสั่งผลิตใหม่ ${createdStatus || ''}`}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {information?.setting?.mms?.allowUseMOTemplate && (
            <MOFromTemplateLoadingForm
              moTemplate={moTemplate}
              setCreateMO={onLoadDataFromTemplate}
            />
          )}
          {browserQuery.get('quotation') && (
            <div className="my-2">
              <Card>
                <CardContent>
                  <div>ตั้งค่าจากใบเสนอราคา</div>
                  <div>
                    เลขที่ใบเสนอราคา {watch('quotation')?.running_number}
                  </div>
                </CardContent>
              </Card>
            </div>
          )}{' '}
          {browserQuery.get('internal-deposit') && (
            <div className="my-2">
              <Card>
                <CardContent>
                  <div>ตั้งจากใบเบิกใช้ภายใน</div>
                  <div>
                    เลขที่ใบเบิกใช้ภายใน{' '}
                    {watch('internal_deposit')?.running_number}
                  </div>
                </CardContent>
              </Card>
            </div>
          )}
          <ManufacturingOrderForm
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            customer={customer}
            materials={materials}
            products={products}
            information={information}
            paymentType={paymentType}
            customerType={customerType}
            processTemplate={processTemplate}
            machine={machine}
            isReady={isReady}
            bomTemplate={bomTemplate}
            baseProject={baseProject}
          />
          <div className="flex flex-row justify-end">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateManufacturingOrder;
