import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  DesktopDatepicker,
  Error,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { currencyFormatter } from '@iarcpsu/emanufac-utils/functions';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function ProcessDeposit
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ProcessDeposit({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const { control, watch, handleSubmit } = useForm();

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processGet(params.id));
    } catch (error) {
      dispatch(actions.processError());
    }
  };

  const handleOnSubmit = async (data) => {
    const depositData = {
      is_deposit: true,
      amount:
        parseFloat(data.add_amount || 0) +
        (currentProcess?.deposit?.amount || 0),
      deposit_date: data.deposit.deposit_date,
    };
    try {
      dispatch(actions.manufacturingOrderReset());
      await dispatch(
        actions.processPut(params.id, {
          deposit: depositData,
          employee: me?.userData?._id,
          updateDeposit: true,
        }),
      );
      Swal.fire({
        title: 'บันทึกมัดจำสำเร็จ',
        icon: 'success',
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        title: 'บันทึกมัดจำไม่สำเร็จ',
        icon: 'error',
        text: error.message,
      });
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Card>
              <CardContent>
                <div className="flex justify-between">
                  <div className="my-1 font-semibold">
                    มัดจำสำหรับสินค้า / ไลน์ผลิตนี้
                  </div>
                </div>
                <div className="my-2">
                  <div className="my-1">วันที่มัดจำ</div>
                  <div className="my-2">
                    <Controller
                      control={control}
                      name="deposit.deposit_date"
                      defaultValue={
                        currentProcess?.deposit?.deposit_date || new Date()
                      }
                      render={({ field }) => (
                        <DesktopDatepicker
                          {...field}
                          value={field.value}
                          fullWidth
                          label="วันที่มัดจำ"
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="my-1 mt-2 font-semibold">
                    รายละเอียดการมัดจำ
                  </div>
                </div>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>ราคาทั้งหมด </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            (currentProcess?.price_per_unit || 0) *
                              currentProcess?.quantity,
                          )}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>ชำระค่ามัดจำไปแล้ว </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            currentProcess?.deposit?.amount || 0,
                          )}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>{' '}
                      <TableRow>
                        <TableCell> ชำระเงินมัดจำเพิ่ม </TableCell>
                        <TableCell>
                          <Controller
                            name="add_amount"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                label="ชำระเงินเพิ่ม"
                                size="small"
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>{' '}
                      <TableRow>
                        <TableCell> รวมเงินมัดจำ </TableCell>
                        <TableCell>
                          {currencyFormatter.format(
                            parseFloat(watch('add_amount') || 0) +
                              (currentProcess?.deposit?.amount || 0),
                          )}
                        </TableCell>
                        <TableCell>บาท</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>{' '}
            <div className="flex justify-end my-1">
              <Button type="submit" variant="contained">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessDeposit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessDeposit.defaultProps = {
  title: '',
  subtitle: '',
};
