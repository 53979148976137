import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  BackButton,
  Error,
  ImageUpload,
  Loading,
  ViewTitle,
} from '@iarcpsu/emanufac-components/src/components';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as actions from '@/redux/actions';

/**
 * @function EmployeeSignature
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EmployeeSignature({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const history = useHistory();
  const params = useParams();
  const [selectedImage, setSelectedImage] = useState([]);

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.employeeGet(params.id));
    } catch (error) {
      dispatch(actions.employeeError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleChangeSignature = async () => {
    try {
      await dispatch(
        actions.employeePut(params.id, {
          signature: { image: selectedImage[0]?.data_url },
        }),
      );
      Swal.fire({
        title: 'แก้ไขสำเร็จ',
        icon: 'success',
      }).then(() => {
        dispatch(actions.employeeGet(params.id));
      });
    } catch (error) {
      Swal.fire({
        title: 'แก้ไขไม่สำเร็จ',
        icon: 'error',
        text: error.message,
      });
    }
  };

  const handleRemoveSignature = async () => {
    try {
      await dispatch(
        actions.employeePut(params.id, {
          signature: null,
        }),
      );
      Swal.fire({
        title: 'แก้ไขสำเร็จ',
        icon: 'success',
      }).then(() => {
        dispatch(actions.employeeGet(params.id));
      });
    } catch (error) {
      Swal.fire({
        title: 'แก้ไขไม่สำเร็จ',
        icon: 'error',
        text: error.message,
      });
    }
  };
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }

  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div>ลายเซ็นต์ปัจจุบัน</div>
              <div>
                <img src={employee?.signature?.url} alt="" width={200} />
              </div>
              <Button
                variant="contained"
                color="error"
                disable={!employee?.signature?.url}
                onClick={handleRemoveSignature}
              >
                ลบลายเซนต์
              </Button>
            </CardContent>
          </Card>
        </div>{' '}
        <div className="my-2">
          <Card>
            <CardContent>
              <div>แก้ไขลายเซนต์</div>
              <ImageUpload
                images={selectedImage}
                maxNumber={1}
                setImages={setSelectedImage}
                title="อัพโหลดลายเซนต์"
              />
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  color="success"
                  disabled={_.size(selectedImage) === 0}
                  onClick={handleChangeSignature}
                >
                  บันทึก
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>{' '}
      </div>
    );
  }
  return <Error />;
}

EmployeeSignature.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EmployeeSignature.defaultProps = {
  title: '',
  subtitle: '',
};
