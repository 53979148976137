import React from 'react';
import { useSelector } from 'react-redux';
import { MANUFACTURING_TRANSACTION_TYPE } from '@iarcpsu/emanufac-constant';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Avatar, Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

dayjs.extend(BuddhistEra);

export default function ManufacturingTransactionBox({
  manufacturingTransaction,
  size,
  setSize,
  showTitle = true,
  container = true,
}) {
  const information = useSelector((state) => state.information);
  const handleIncrementSize = () => {
    setSize(size + 5);
  };

  const employeeName = (employee) => {
    if (information?.setting?.hrms?.use_display_name) {
      if (employee?.display_name) {
        return employee?.display_name;
      }
      return `${employee?.firstname} ${employee?.lastname}`;
    }

    return `${employee?.firstname} ${employee?.lastname}`;
  };

  const renderTranactionList = () => (
    <div className="my-4 max-h-96 overflow-y-auto">
      {_.size(manufacturingTransaction?.rows) === 0 && (
        <div className="p-4 text-center">ยังไม่มีกระบวนการในการผลิตนี้</div>
      )}
      {size < (manufacturingTransaction?.total || 0) && (
        <div>
          <Button onClick={handleIncrementSize}> โหลดเพิ่ม </Button>
        </div>
      )}
      <Timeline position="alternate">
        {_.map(
          _.filter(
            manufacturingTransaction?.rows,
            (_transaction) =>
              _transaction?.manufacturing_transaction_type !==
                MANUFACTURING_TRANSACTION_TYPE.PROCESS_UPDATE.status_code &&
              _transaction?.manufacturing_transaction_type !==
                MANUFACTURING_TRANSACTION_TYPE.MATERIAL_WASTED.status_code,
          ),
          (_manufacturingTransaction, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {dayjs(_manufacturingTransaction?.createdAt).format(
                  'D MMM  HH.mm น.',
                )}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <div className="p-2">
                  <Avatar
                    src={_manufacturingTransaction?.employee?.image?.url}
                    className="uppercase  "
                    sx={{ width: 32, height: 32 }}
                  >
                    {_.first(employeeName(_manufacturingTransaction?.employee))}
                  </Avatar>
                </div>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <div className="flex flex-col align-center">
                  <div className="text-sm">
                    {employeeName(_manufacturingTransaction?.employee)}
                  </div>

                  <div className="font-semibold text-black font-display">
                    {
                      MANUFACTURING_TRANSACTION_TYPE?.[
                        _manufacturingTransaction
                          ?.manufacturing_transaction_type
                      ]?.description
                    }{' '}
                  </div>
                  <div className="text-sm">
                    {_manufacturingTransaction?.step?.name}
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          ),
        )}
      </Timeline>
    </div>
  );

  return (
    <div>
      {container ? (
        <Card>
          <CardContent>
            {showTitle && (
              <div className="font-semibold font-display">รายการงาน</div>
            )}
            {renderTranactionList()}
          </CardContent>
        </Card>
      ) : (
        <div>
          {showTitle && (
            <div className="font-semibold font-display">รายการงาน</div>
          )}
          {renderTranactionList()}
        </div>
      )}
    </div>
  );
}

ManufacturingTransactionBox.propTypes = {
  manufacturingTransaction: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    currPage: PropTypes.number,
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,

    lastPage: PropTypes.number,
    total: PropTypes.number,
  }),
  size: PropTypes.number,
  showTitle: PropTypes.bool,
  setSize: PropTypes.func,
  container: PropTypes.bool,
};
